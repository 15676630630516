// NORWEGIAN (nb_NO)

export default {
  // START Aria translation
  aria_enter: `Start på donasjonen`,
  aria_flow: `Start på donasjonen. Seksjon {{sectionIndex}} av {{totalSteps}}`,
  // END Aria translation

  _verification: 'Verifisering pågår...',
  _timeout: 'Det har oppstått en tidsavbruddsfeil. Prøv igjen senere.',
  _confirmation_boundary_error: `Det har oppstått en feil. Men transaksjonen din er behandlet og vil vises på kontoutskriften din.`,
  _unable_loading_error: `Kan ikke laste inn innhold for øyeblikket.`,
  _configuration_error: 'Konfigurasjonsfeil oppstod',
  _loading: `Laster inn ...`,
  _loading_image: `Laster inn bilde ...`,
  _select: `Velg`,
  _month: `Måned`,
  _year: `år`,
  _or: `Eller`,

  amount: '<1>{{currencySymbol}}{{amount}}</1>', // not in use

  processing: {
    donation: `Bearbeider ditt bidrag/din gave...`,
    donation_donate: 'Bearbeider ditt bidrag/din gave...',
    donation_subscribe: 'Behandler abonnementet ditt...',
    contact: `Oppdaterer kontaktinformasjonen din...`,
    paymentInfo: `Oppdaterer betalingsinformasjonen din...`,
    failure_message: `Kunne ikke behandle betalingen`,
    intent_failure_message: 'Kunne ikke opprette betalingshensikt',
    fetching_pages: `Henter innsamlingssider...`,
    recaptcha_token_error:
      'Kunne ikke hente reCAPTCHA-token på dette tidspunktet. Vennligst prøv igjen senere.',
    recaptcha_failure:
      'Dårlig forespørsel, dårlig recaptcha-token. Dobbeltsjekk informasjonen din og prøv igjen.',
  },

  image: {
    no_alt:
      'Et dynamisk bilde ble lastet inn, men ingen alternativ tekst ble levert',
  },

  title: {
    how_often_donate: `Hvor ofte vil du gi bidrag?`,
    select_amount_donate: `Velg beløpet du ønsker å gi:`,
    select_amount_subscribe: `Du abonnerer for øyeblikket for å gi:`,
    direct_your_gift: `Hvordan vil du gi gaven din/bidraget ditt?`,
    bank: `Bank`,
    basc: 'BACS Direct Debit',
    select_payment_method: `Velg betalingsmetoden din`,
    wallets: 'Lommebøker',
    apple_pay: `Apple Pay`,
    google_pay: `Google Pay`,
    card: `Kort`,
    lifetimeGiving: `Livstid`,
    givingHistory: `Gavehistorikk`,
    thisYearGiving: `Dette året`,
    lastGiftAmount: `Beløp siste donasjon`,
    recurringGift: `Fast donasjon`,
    creditCard: `Bankkort`,
    childRecurringGift: `Status for barns gjentakende gave`,
    next: `Neste`,
    last: `Siste`,
    cardType: `Korttype`,
    expiry: `Utløpsdato`,
    cancelled: `Avbrutt`,
    gift_aid: `Gift Aid`, // Not relevant in Norway - using Google Translation
    donor_details: `Informasjon om giver`,
    subscribe_details: 'Abonnentdetaljer',
    payment_details: `Betalingsmuligheter`,
    additionalInfo: `Mer informasjon`,
    membership: `Medlemskap`,
    show_support: `Del en støttesmelding`,
    share_on: `Del på`, // CP
    share_generosity: `Del din generøsitet og inspirer andre:`,
    help_further: `Vil du bidra mer?`,
  },

  button: {
    other_btn: `Annet`,
    cancel_btn: `Avbryt`,
    search_btn: `Søk`,
    new_gift_btn: `Ny gave`,
    create_new_contact_btn: `Opprett gave for ny giver`,
    update_contact_info: `Oppdater kontaktinfo`,
    update_payment_info: `Oppdater betalingsinfo`,
    update_payment_schedule: `Oppdater betalingsplan`,
    edit_btn: 'Redigere',
    view_btn: `Vis`,
    share_btn: 'Dele',
    save_changes: `Lagre endringer`,
  },

  navigation: {
    next_btn: 'Neste',
    prev_btn: `Forrige`,
    update_btn: `Oppdater`,
    finish_btn: 'Bli ferdig',
    donate_btn: 'Donere',
    subscribe_btn: 'Abonnement',
    membership_btn: `Medlemskap`,
    ptx_verification: 'Bekrefte',
    authorization:
      'Ved å fullføre dette trinnet, godkjenner du lagring av denne kortkontoen for: <strong><1>{{amount}}</1> / per <2>{{recurring}}</2>, inntil den kanselleres</strong>',
  },

  link: {
    read_more: `Les mer`,
    show_less: `Vis mindre`,
    donate_monthly_link: `Jeg vil gjerne gi en gave månedlig`,
    donate_annually_link: `Jeg vil gjerne gi en gave årlig`,
    privacy_policy: 'Personvernregler',
    terms_conditions: 'Vilkår og betingelser',
    your_donation: `Din gave/ <1>{{currency}}</1>`,
    your_monthly_donation: `Din månedlige gave <1>{{currency}}</1>`,
    your_annually_donation: `Din årlige gave <1>{{currency}}</1>`,
    your_subscription: `Din abonnement/ <1>{{currency}}</1>`,
    your_monthly_subscription: `Din månedlige abonnement <1>{{currency}}</1>`,
    your_annually_subscription: `Din årlige abonnement <1>{{currency}}</1>`,
    your_membership: `Ditt medlemskap <1>{{currency}}</1>`,
    giving: 'Gi <1>{{currency}}</1>',
  },

  forms: {
    no: `Ikke`,
    yes: 'Ja',
    required: 'Obligatorisk',
  },

  contactForm: {
    honour_dedication: `Til ære for`,
    memory_dedication: `I minne om`,
    no_send_tribute: `Nei\ntakk`,
    email_send_tribute: `Ja, send\nen e-post`,
    mail_send_tribute: `Ja, send\net kort`,
    company_gift: `Dette er en bedriftsgave`,
    company: `Firmanavn`,
    company_valication: `Skriv inn firmanavn`,
    company_help_text: `Firmanavnet eller organisasjonsnavnet som vil vises på bekreftelsen og/eller kvitteringen.`,
    first_name: 'Fornavn',
    first_name_validation: `Fyll inn fornavn`,
    last_name: 'Etternavn',
    last_name_validation: `Fyll inn etternavn`,
    anonymous_help_text: `Dette vil skjule navnet ditt og donorinformasjon fra alle offentlige kommunikasjoner. Organisasjonen vil fortsatt motta informasjonen din.`,
    email: `e-post`,
    email_validation: `Fyll inn gyldig e-post adresse`,
    email_help_text_donate: `Bekreftelsen og/eller skattekvitteringen din vil bli sendt til denne e-postadressen.`,
    email_help_text_subscribe:
      'Enhver bekreftelse eller kommunikasjon om dette abonnementet vil bli sendt her.',
    phone: `Mobil`,
    phone_validation: `Fyll inn et gyldig mobilnummer uten desimaler eller bindestreker`,
    mobile: 'Mobil',
    mobile_validation:
      'Vennligst skriv inn et gyldig mobilnummer uten desimaler eller bindestreker',
    postal_code: `Postnummer`,
    postal_code_validation: `Fyll inn gyldig postnummer`,
    address: `Adresse1`,
    address_validation: `Skriv inn adresse`,
    address_auto_complete_help_text:
      'Adresse 1-feltet har autofullføring aktivert. 5 tegn kreves før forslag gis.',
    address2: `Adresse2`,
    city: 'By',
    city_validation: `Skriv inn by`,
    province: `Region`, // Do not want that field - used Google Translation
    province_validation: `Region`, // Do not want that field - used Google Translation
    country: `Land`,
    country_validation: `Fyll inn gyldig land`,
    employer_matches_checkbox: `Min arbeidsgiver vil matche gaven`,
    employer: `Min arbeidagiver`,
    honouree_label: 'Honoree navn',
    honouree_validation: `Vennligst skriv inn et gyldig navn`, // used for employer validation - missing?
    dedication_checkbox: `Ja, mitt bidrag er til ære for eller i minne om noen`,
    tribute_type: `Vil du at vi skal sende en e-post eller et kort?`,
    recipient_email: `Skriv inn mottakerens e-postadresse`,
    recipient_email_validation: `Fyll inn mottakers email eller fysiske adresse`,
    addressee_name: `Send kort til`,
    addressee_name_validation: `Skriv inn mottakers navn`,
    special_message: `Del din personlige melding her. (Valgfritt)`,
    double_donation: 'Se om din bedrift vil matche donasjonen din!',
  },

  questionsForm: {
    required_message: 'Svar på spørsmålene nedenfor kreves.',
    custom_questions_error: 'Svar på alle spørsmål kreves',
  },

  paymentForm: {
    // Bank
    transit_no: `Overføringsnummer`,
    tran_no_validation: `Vennligst skriv inn et gyldig overføringsnummer`,
    routing_no: `Routing Number`,
    routing_no_validation: `?`, // Not relevant in Norway - Adding Google Translation
    sort_code: `Sort code`,
    sort_code_validation: `?`, // Not relevant in Norway - Adding Google Translation
    bsb_no: `BSB-nummer`,
    bsb_no_validation: `Vennligst skriv inn et gyldig BSB-nummer`,
    bank_check: `Banksjekk`, // Not relevant in Norway - using Google Translation
    bank_name: `Navn på bank`,
    bank_name_validation: `Navn på bank er påkrevd`,
    bank_name_help_text: `Banknavnet kan ikke overstige 18 tegn`,
    acc_no: `Kontonummer`,
    acc_no_validation: `Vennligst skriv inn et gyldig kontonummer`,
    inst_no: `Organiasjonsnummer`,
    inst_no_validation: `Vennligst skriv inn et gyldig organiasjonsnummer`,
    // Credit Card
    card_number: `Kortnummer`,
    card_validation: `Fyll inn et gyldig kortnummer`,
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      'Angi en gyldig utløpsdato (MM). Det første sifferet er 0 for ensifrede måneder.',
    expiry_date_yy: 'ÅÅ',
    expiry_date_yy_validation: 'Angi en gyldig utløpsdato (ÅÅ).',
    expiry_date_mmyy: 'MM/ÅÅ',
    card_security_code: 'CVV',
    card_security_code_validation: `Fyll inn CVV`,
    use_existing_credit_card: `Bruk eksisterende kort`,
    // PTX
    ptx_verified_message: 'Validering vellykket',
    adyen_error_message: `En betalingsfeiltype: {{ resultCode }} har oppstått. Dobbeltsjekk informasjonen din eller velg en annen betalingsmetode.`,
  },

  frequencySelector: {
    donate_single: 'Doner\nen gang',
    donate_monthly: 'Doner\nmånedlig',
    donate_annually: `Gi\nårlig`,
    subscribe_single: 'Engangsabonnement',
    subscribe_monthly: 'Abonner\nmånedlig',
    subscribe_annually: 'Abonner\nårlig',
  },

  amountSelector: {
    minimum_donation_of: `Minimumsbeløp`,
  },

  designationSelector: {
    greatest_need: `Største behov`,
    selection_label: `Annet`,
    order_label: `Velg`,
  },

  giftAid: {
    // giftAid_checkbox:	Not relevant for Norway - Using Google Translation
    giftAid_donate_checkbox:
      'Ja, jeg vil gjerne legge til et gavehjelp på <1>{{gift}}</1> (25% av donasjonen) på toppen av <2>{{donation}}</2> -donasjonen min.',
    giftAid_subscribe_checkbox:
      'Ja, jeg betaler skatt i Storbritannia, og jeg ønsker å få det til å bety mer med Gift Aid.',
  },

  confirmation: {
    thank_you: 'Takk, {{name}}',
    donation_received_OCP: `Ditt bidrag er motatt`,
    donation_received: `Din donasjon på <1>{{donationAmount}}</1> har blitt mottatt.`,
    subscription_received: `Din abonnement på <1>{{donationAmount}}</1> har blitt mottatt.`,
    receipt: `En bekreftelse vil bli sendt til <em>{{email}}</em>.`,
    redirectMessage: `Du vil bli omdirigert om:`,
    seconds: `sekunder`,
  },

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: `Samlet inn?`,
  goal: `Mål`, // CP
  of_goal: 'av mål', // CP
  supporters: `Støttespillere`, // CP
  days_left: 'Dager igjen', // CP

  Amount: `Beløp`,
  Name: `Navn`,
  Team: `Lag/Team`,
  Total: `Totalt`,

  individual_subtitle: `Start din egen innsamling nedenfor.`,
  team_subtitle: `Start din laginnsamling nedenfor.`,
  campaign_title: `Kjør! `,

  individual_page_name_label: `Innsamlingnavn`,
  team_page_name_label: `Navn på laginnsamlingsside`,
  team_member_page_name_label: `Navn på individuell innsamlingsside`,
  individual_page_goal_label: `Individuelle innsamlingsmål`,
  team_page_goal_label: `Laginnsamlingsmål`,
  team_member_page_goal_label: `Individuelt innsamlingsmål`,

  team_campaign_name: `Navn på laginnsamlings-team`,
  campaign_name: `Navn på innsamlingsside`,
  campaign_name_validation: `Vennligst skriv inn et gyldig innsamlingsnavn`,
  campaign_goal: `Innsamlingsmål`,
  team_campaign_goal: `Laginnsamlingsmål`,
  campaign_url: `Kampanje-URL`,

  create_campaign_btn: `Opprett innsamlingsside`,
  update_campaign_btn: `Lagre endringer`,
  delete_campaign_btn: `Slett denne siden`,
  cancel_changes_btn: `Avbryt endring`,

  password: `Passord`,
  password_validation: `Vennsligst skriv inn passord`,
  password_reset_success: `Tilbakestillingsinstruksjoner for passordet har blitt sendt til {{email}}.`,
  forgot_password: `Glemt passordet?`,
  old_password: `Gammelt passord`,
  new_password: `Nytt passord`,
  forgot_password_title: `Tilbakestill passord`,
  forgot_password_btn: `Send tilbakestillingsinstruksjoner`,
  change_password_title: `Endre passord`,
  failed_password_change: `Gammelt passord stemmer ikke`,
  success_password_change: `Passordet ble oppdatert`,

  sign_in: 'Logg inn',
  sign_up: `Registrer deg`,
  start_your: `Start din innsamling!`,
  dont_have: `Har du ikke en konto?`,
  already_have: `Har du allerede en konto?`,
  go_back: `Gå tilbake`,

  edit_campaign: `Rediger kampanje`,
  log_out: `Logg ut`,
  activity: `Aktivitet`,
  settings: `Innstillinger`,

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: `{{count}} tegn gjenstår`,
  start_a_campaign_btn: `Start en kampanje`,
  campaign_photo: `Innsamlingssidebilde`,
  recommended_photo_size: `Anbefalt bildestørrelse: 1240 x 600 piksler`,
  edit_profile_title: `Rediger profil`,
  edit_campaign_title_individual: `Rediger individuell innsamlingsside`,
  edit_campaign_title_team: `Rediger laginnsamlingsside`,
  edit_campaign_title_team_member: `Rediger lagmedlemmets innsamlingsside`,
  return_back_btn: `Gå tilbake til profilen`,
  changes_saved_btn: `Endringer lagret!`,
  no_changes_btn: `Ingen endringer å lagre`,
  browse: `Bla gjennom`,
  drag_drop_pic: `Dra og slipp en bilde for å laste opp`,
  cancel_image_upload: `Avbryt opplasting av bilde`,

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question: `Er du sikker på at du vil slette denne innsamlingssiden?`,

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: `Bli med i laget`,

  // CampaignForm
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: `Bli med i {{name}}`,
  join_as_individual_btn: `Bli med som enkeltperson`,
  create_a_team_btn: `Opprett et lag`,
  create_a_new_team_btn: `Opprett et nytt lag`,
  welcome: `Velkommen`,
  individual_select_message: `Velg om du vil samle inn til et innsamlingsteam, eller redigere din profil`,
  member_select_message: `Velg om du vil opprette et nytt innsamlingsteam, eller redigre din profil`,
  team_select_message: `Se din profil`,
  ind_or_team_message: `Velg om du vil opprette en egen innsamling, eller opprette et innsamlingsteam`,
  click_here: `Klikk her`,
  prefer_individual: `Hvis du heller vil starte en laginnsamling,`,
  prefer_team: `Hvis du heller vil starte en egen innsamling,`,

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  donated_by: `Donasjon gitt av`,
  campaign_supporters: `Kampanjestøttespillere`,
  campaign_sponsor: `Våre generøse sponsorer og partnere`,

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name: `Skriv inn et visningsnavn her - det vil vises på innsamlingssiden`,
  leave_message: `Del din støttemelding - dette vil vises på organisasjons- eller innsamlernes side slik at de vet at de har mottatt en gave fra deg (valgfritt)`,
  support_display_all: `Vis navn, melding og gavebeløp. `,
  support_hide_amount: `Vis kun navn og melding (skjuler gavebeløp).`,
  support_hide_name: `Vis kun gavebeløp og melding (skjuler navn).`,
  support_hide_all: `Nei, skjul mitt navn, melding og gavebeløp`,
  email_exists: `Denne e-postadressen er allerede registrert`,

  anonymous: `Anonym`,
  fundraising_team_for: `Innsamlingslag for:`,
  fundraising_for: `Samler inn midler for:`,
  fundraising_team_member: `Medlem av;`,

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: `Lagmedlemmer`,
  no_pages_to_display: `Ingen innsamlingssider å vise.`,
  fundraising_teams: `Innsamlingslag/Innsamlingsteam`,
  individual_fundraisers: `Innsamlere `,

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: `Medlem av:`,
  team_member_of: `Lagmedlem av:`,
  role_team_member: `(Rolle: Lagmedlem)`,
  role_team_captain: `(Rolle: Lagleder)`,

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages: `Du har ingen innsamlingssider.`,
  no_team_pages: `Du har ingen laginnsamlingssider.`,

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: `Støttemedlemsnummer/Komponentnummer?`,
  update_saved_message: `Oppdatering lagret`,

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: `404`,
  subtitle_404: `Beklager, vi kan ikke finne siden du leter etter`,
  message_404: `Ta meg tilbake til - / Tilbake til -`,
  home_page: `Hjemmeside`,

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: `Oppretter ny gave`,

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: `Kanseller gave`,
  back: `Tilbake`,
  notPaymentScheduleMessage: `Ingen betalingsplan tilgjengelig`,

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: `Start med å skrive inn en`,

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx\
  note: `Merk deg:`,
  FeltPressure: `Giver følte på press fra innsamler`,
  NoLongerAfford: `Kan ikke lenger betale/Har ikke råd`,
  UnderMinimumWage: `Under minimumsalder`,
  SpouseObjected: `Ektefelle/samboer protesterte`,
  ChangedMind: `Endret mening`,
  IntendedShortTerm: `Giver hadde til hensikt å gi en kortvarig periode`,
  IntendedOneTime: `Giver hadde til hensikt å gi en enkeltgave/ett enkeltbidrag`,
  Deceased: `Avdød/Død`,
  Misunderstood: `Misforstod forpliktelsen`,
  OtherCharities: `Donerer til andre organisasjoner`,
  PaymentDeclined: `Betalingen ble avvist`,
  Complaint: `Klage`,
  AccountClosed: `Kontoen er stengt`,
  AlreadyDonating: `Gir allerede`,
  NoReason: `Ingen grunn oppgitt`,

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: `Hold meg innlogget`,
  session_timeout: `Økten går ut`,
  session_message: `Denne økten går ut om <1>{{countdown}}</1> sekunder.`,
  continue_confirmation: `Vil du fortsette?`,

  // DonateBox & DonateButton CP
  master_donate_btn: `Gi ett bidrag til denne kampanjen`,
  team_donate_btn: `Gi ett bidrag til laget`,
  team_member_donate_btn: `Gi ett bidrag til {{name}}s innsamling`,

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: `Bidraget ble gitt gjennom`,
  donation: `Bidrag`,

  // OCP DonationGroup > Accordion
  why_donate_monthly: `Hvilken effekt har ett månedtlig bidrag?`,

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  Completed: `Fullført`,
  Terminated: `Avbrutt/Kansellert`,
  Failed: `Feil oppstod`,
  TransactionRefund: `Refusjon`,
  InProgress: `Pågår`,

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: `Ingen betalingsmetode tilgjengelig`,

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox: `Inkluder medlemskapet mitt i min <1>{{donation}}</1> <2>{{donationFrequency}}</2> donasjon.`,
  membership_order_label: `Velg (valgfritt)`,

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: `Engasjer deg`,
  set_up_fundraising_btn: `Sett opp innsamlingskampanjen min`,
  start_fundraising_accordian_title: `Støtt en kampanje/innsamling`,

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description: `Klikk på 'Din gave' for å endre beløpet på gaven din`,
  summary_link_subscribe_description: `Klikk "Ditt abonnement" for å endre beløpet`,
  unexpected_error_message:
    'Det har oppstått en feil. Men transaksjonen din er behandlet og vil vises på kontoutskriften din. <1>Ikke prøv igjen.</1><br /><br /><2>Klikk her</2> eller kontakt vårt givertjenesteteam umiddelbart for å informere om at du har mottatt denne meldingen.',
  applePay_error: `Apple Pay er kun tilgjengelig når den er aktivert med iOS-kompatible enheter (iPhone/iPad/Mac).`,
  googlePay_error: `GooglePay er ikke aktivert på denne enheten`,

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg: `Hvis du har noen kommentarer knyttet til din gave, vennligst inkluder dem her.`,
  notes_or_direction: `Del eventuelle ytterligere kommentarer eller veiledning knyttet til donasjonen din nedenfor`,
  nols_additional_info_msg:
    'Hvis du har noen kommentarer knyttet til din gave, vennligst inkluder dem her.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: `Legg til profilbilde`,
  upload_profile_picture_checkbox: `Jeg vil gjerne laste opp et profilbilde`,

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: `Sorter etter`,
  top_fundraisers: `Innsamlere på topp`,
  most_recent: `Sist oppdatert`,
  alphabetical: `Alfabetisk`,

  // CAMPAIGN PAGES!!!!!!!!!!
  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: `Bytt profilbilde/Endre profilbilde`,
  start_p2p_btn: 'Start innsamling',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: `Jeg ønsker å gi ett bidrag til denne kampanjen`,
  team_title_donate_now: `Jeg ønsker å gi ett bidrag til dette teamet`,
  title_start_fundraiser: `Jeg vil starte min egen innsamling`,
  title_join_team: `Jeg vil samle inn midler som ett team`,
  title_view_team: `Se ditt teams innsamlingsaktiveter`,
  title_join_individual: `Jeg ønsker å samle inn midler som enkeltperson`,
  title_view_individual: `Se din aktivitet`,
  master_title_join_team: `Jeg ønsker å samle inn midler som et team`,
  team_title_join_team: `Jeg vil bli med på dette laget`,
  donate_this_campaign: `Gi gave til denne innsamlingen`,
  donate_now: 'Donér\nnå',
  start_fundraiser: `Start en innsamling nå`,
  create_fundraiser: `Opprett en\ninnsamling`,
  master_join_team: `Opprett et\nteam`,
  team_join_team: `Bli med på\ndette laget`,
  view_team: `Vis ditt teams\ninnsamlingsside`,
  join_individual: `Bli med som\nenkeltperson`,
  view_individual: `Din side`,
  // END P2P actionButtonSet and P2P Action
  view_dashboard: `Se dashboard`,
  donate_today: `Gi et bidrag nå`,
  select_heading: `Velg gave`,
  choose_details: `Velg detaljene du vil vise på organisasjonens- eller innsamlingsstedsiden:`,
  required_pwd: `Vennligst skriv inn passordet ditt/Skriv inn passord`,
  pwd_match: `Må inneholde 8 tegn, en stor bokstav, en liten bokstav, ett tall og ett spesialtegn`,
  // END CAMPAIGN PAGES!!!!!!!!!!
};
