// ENGLISH CANADA (en_CA)

// Donation Page/flow
import { dp } from './dp';
// Campaign Page
import { cp } from './cp';
// Event Page
// import { ep } from './ep';

export default {
  ...dp,
  ...cp,

  // START Aria translation
  aria_enter: 'Beginning donation section',
  aria_flow:
    'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  // END Aria translation

  _verification: 'Verification in progress...',
  _timeout: 'A timeout error has occurred. Please try again later.',
  _confirmation_boundary_error: `An error has occurred. But your transaction has been processed and will appear on your statement.`,
  _unable_loading_error: `Unable to load content at this time.`,
  _configuration_error:
    'Configuration error occurred. Please contact our team immediately',
  _loading: 'Loading...',
  _loading_image: 'Loading image...',
  _select: 'Select',
  _month: 'Month',
  _year: 'Year',
  _or: 'Or',

  amount: '<1>{{currencySymbol}}{{amount}}</1>', // not in use

  processing: {
    donation_donate: 'Processing your donation...',
    donation_subscribe: 'Processing your subscription...',
    contact: 'Updating your Contact Information...',
    paymentInfo: 'Updating your Payment Information...',
    failure_message: 'Failed to process payment',
    intent_failure_message: 'Failed to create payment intent',
    fetching_pages: 'Fetching fundraising pages...', // CP
    recaptcha_token_error:
      'Failed to retreive reCAPTCHA Token at this time. Please try agian later.',
    recaptcha_failure:
      'Bad Request, bad recaptcha token. Please double check your information and try again.',
  },

  image: {
    no_alt: 'A dynamic image was loaded but no alt text was provieded',
  },

  title: {
    how_often_donate: 'How often would you like to donate?',
    select_amount_donate: "Select the amount you'd like to give:",
    select_amount_subscribe: 'You’re currently subscribed to give:',
    direct_your_gift: 'How would you like to direct your gift?',
    bank: 'Bank',
    basc: 'BACS Direct Debit',
    select_payment_method: 'Select Your Payment Method',
    wallets: 'Wallets',
    apple_pay: 'Apple Pay',
    google_pay: 'Google Pay',
    card: 'Card',
    lifetimeGiving: 'Lifetime',
    givingHistory: 'Giving History',
    thisYearGiving: 'This Year',
    lastGiftAmount: 'Last Gift Amount',
    recurringGift: 'Recurring Gift',
    creditCard: 'Credit Card',
    childRecurringGift: 'Child Recurring Gift Status',
    next: 'Next',
    last: 'Last',
    cardType: 'Card Type',
    expiry: 'Expiry',
    cancelled: 'Cancelled',
    gift_aid: 'Gift Aid',
    donor_details: 'Donor Details',
    subscribe_details: 'Subscriber Details',
    payment_details: 'Payment Options',
    additionalInfo: 'Additional Information',
    membership: 'Membership',
    show_support: 'Share a message of support', // CP
    share_on: 'Share On', // CP
    share_generosity: 'Share your generosity and inspire others:',
    help_further: 'Want to help further?',
  },

  button: {
    other_btn: 'Other',
    cancel_btn: 'Cancel',
    search_btn: 'Search',
    new_gift_btn: 'New Gift',
    create_new_contact_btn: 'Create Gift for New Donor',
    update_contact_info: 'Update Contact Info',
    update_payment_info: 'Update Payment Info',
    update_payment_schedule: 'Update Payment Schedule',
    edit_btn: 'Edit', // CP
    view_btn: 'View', // CP
    share_btn: 'Share',
    save_changes: 'Save Changes',
  },

  navigation: {
    next_btn: 'Next',
    prev_btn: 'Previous',
    update_btn: 'Update',
    finish_btn: 'Finish',
    donate_btn: 'Donate',
    subscribe_btn: 'Subscription',
    membership_btn: 'Membership',
    ptx_verification: 'Verify',
    authorization:
      'By completing this step, you authorize the storing of this card account for: <strong><1>{{amount}}</1> / per <2>{{recurring}}</2>, until cancelled</strong>',
  },

  link: {
    read_more: 'Read more',
    show_less: 'Show less',
    donate_monthly_link: "I'd like to donate monthly",
    donate_annually_link: "I'd like to donate annually",
    privacy_policy: 'Privacy Policy',
    terms_conditions: 'Terms and Conditions',
    your_donation: 'Your donation <1>{{currency}}</1>',
    your_monthly_donation: 'Your monthly donation <1>{{currency}}</1>',
    your_annually_donation: 'Your annual donation <1>{{currency}}</1>',
    your_subscription: 'Your subscription <1>{{currency}}</1>',
    your_monthly_subscription: 'Your monthly subscription <1>{{currency}}</1>',
    your_annually_subscription: 'Your annual subscription <1>{{currency}}</1>',
    your_membership: 'Your membership <1>{{currency}}</1>',
    giving: 'Giving <1>{{currency}}</1>',
  },

  forms: {
    no: 'No',
    yes: 'Yes',
    required: 'Required',
  },

  contactForm: {
    honour_dedication: 'In honour of...',
    memory_dedication: 'In memory of...',
    no_send_tribute: 'No,\nthank you',
    email_send_tribute: 'Yes, send\nan email',
    mail_send_tribute: 'Yes, send\na card',
    company_gift: 'This is a corporate donation',
    company: 'Company Name',
    company_valication: 'Please enter the Company Name',
    company_help_text:
      'The Company or Organization name which will appear on the acknowledgement and/or receipt.',
    first_name: 'First Name',
    first_name_validation: 'Please enter your first name',
    last_name: 'Last Name',
    last_name_validation: 'Please enter your last name',
    anonymous_help_text:
      'This will hide your name and donor information from all public communications; however, the charity will still receive your information.',
    email: 'Email',
    email_validation: 'Please enter a valid email address',
    email_help_text_donate:
      'Your acknowledgement and/or tax receipt will be emailed here.',
    email_help_text_subscribe:
      'Any acknowledgment or communication about this subscription will be sent here.',
    phone: 'Phone',
    phone_validation:
      'Please enter a valid phone number without decimals or dashes',
    mobile: 'Mobile',
    mobile_validation:
      'Please enter a valid mobile number without decimals or dashes',
    postal_code: 'Postal Code',
    postal_code_validation: 'Please enter a valid postal code',
    address: 'Address 1',
    address_validation: 'Please enter your address',
    address_auto_complete_help_text:
      'Address 1 field has autocomplete activated. 5 characters are required before suggestions are provided.',
    address2: 'Address 2',
    city: 'City',
    city_validation: 'Please enter your city',
    province: 'Province',
    province_validation: 'Please enter your Province',
    country: 'Country',
    country_validation: 'Please enter a valid country',
    employer_matches_checkbox: 'My employer will match donations',
    employer: 'My Employer',
    honouree_label: 'Please enter their name',
    honouree_validation: 'Please enter a valid name', // used for employer validation - missing?
    dedication_checkbox:
      'Yes, my donation is in honour or in memory of someone',
    tribute_type: 'Would you like us to send an email or card?',
    recipient_email: "Enter the recipient's email address",
    recipient_email_validation:
      "Please enter recipient's email or physical address",
    addressee_name: 'Send Card To',
    addressee_name_validation: "Please enter recipient's name",
    special_message: 'Share your special message here. (Optional)',
    double_donation: 'See if your company will match your donation!',
  },

  questionsForm: {
    required_message: 'Responses to the questions below are required.', // Google translation
    custom_questions_error: 'Responses to all questions are required', // Google translation
  },

  paymentForm: {
    // Bank
    transit_no: 'Transit Number',
    tran_no_validation: 'Please enter a valid transit number',
    routing_no: 'Routing Number',
    routing_no_validation: 'Please enter a valid routing Number',
    sort_code: 'Sort Code',
    sort_code_validation: 'Please enter a valid sort code',
    bsb_no: 'BSB Number',
    bsb_no_validation: 'Please enter a valid BSB number',
    bank_check: 'Bank Check',
    bank_name: 'Bank Name',
    bank_name_validation: 'Bank name is required',
    bank_name_help_text: `The bank name cannot exceed 18 characters`,
    acc_no: 'Account Number',
    acc_no_validation: 'Please enter a valid account number',
    inst_no: 'Institution Number',
    inst_no_validation: 'Please enter a valid institution number',
    // Credit Card
    card_number: 'Card Number',
    card_validation: 'Please enter a valid credit card number',
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      'Please enter a valid expiration date (MM). The first digit is 0 for single-digit months.',
    expiry_date_yy: 'YY',
    expiry_date_yy_validation: 'Please enter a valid expiration date (YY).',
    expiry_date_mmyy: 'MM/YY',
    card_security_code: 'CVV',
    card_security_code_validation: 'Please enter the CVV',
    use_existing_credit_card: 'Use existing credit card',
    // PTX
    ptx_verified_message: 'Validation Successful',
    adyen_error_message: `A payment failure type: {{ resultCode }} has occurred, please double check your information or select a different payment method.`,
  },

  frequencySelector: {
    donate_single: 'Donate\nOnce',
    donate_monthly: 'Donate\nMonthly',
    donate_annually: 'Donate\nAnnually',
    subscribe_single: 'Onetime\nSubscription',
    subscribe_monthly: 'Subscribe\nMonthly',
    subscribe_annually: 'Subscribe\nAnnually',
  },

  amountSelector: {
    minimum_donation_of: 'Minimum donation of',
  },

  designationSelector: {
    greatest_need: 'Greatest Need',
    selection_label: 'Other',
    order_label: 'Please select',
  },

  giftAid: {
    giftAid_donate_checkbox:
      "Yes, I'd like to add a gift aid of <1>{{gift}}</1> (25% of the donation) on top of my <2>{{donation}}</2> donation.", // page
    giftAid_subscribe_checkbox:
      'Yes, I pay tax in the UK and I want to make it mean more with Gift Aid.', // page
  },

  confirmation: {
    thank_you: 'Thank you, {{name}}',
    donation_received_OCP: 'Your donation has been received.',
    donation_received:
      'Your donation of <1>{{donationAmount}}</1> has been received.',
    subscription_received:
      'Your subscription of <1>{{donationAmount}}</1> has been received.',
    receipt: 'An acknowledgement will be sent to <em>{{email}}</em>.',
    redirectMessage: 'You will be redirected in:',
    seconds: 'seconds',
  },

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: 'Raised', // CP
  goal: 'Goal', // CP
  of_goal: 'of Goal', // CP
  supporters: 'Supporters', // CP
  days_left: 'Days Left', // CP

  Amount: 'Amount',
  Name: 'Name',
  Team: 'Team',
  Total: 'Total',

  individual_subtitle: 'Start your individual fundraiser below.',
  team_subtitle: 'Start your team fundraiser below.',
  campaign_title: "Let's go!",

  individual_page_name_label: 'Individual Fundraising Page Name',
  team_page_name_label: 'Team Fundraising Page Name',
  team_member_page_name_label: 'Team Member Fundraising Page Name',
  individual_page_goal_label: 'Individual Fundraising Goal',
  team_page_goal_label: 'Team Fundraising Goal',
  team_member_page_goal_label: 'Team Member Fundraising Goal',

  team_campaign_name: 'Team Fundraising Name',
  campaign_name: 'Fundraising Page Name',
  campaign_name_validation: 'Please enter a valid fundraising campaign name',
  campaign_goal: 'Fundraising Goal',
  team_campaign_goal: 'Team Fundraising Goal',
  campaign_url: 'Campaign URL',

  create_campaign_btn: 'Create Fundraising Page',
  update_campaign_btn: 'Save Changes',
  delete_campaign_btn: 'Delete this page',
  cancel_changes_btn: 'Cancel Changes',

  password: 'Password',
  password_validation: 'Please enter your password',
  password_reset_success:
    'Password reset instructions have been sent to {{email}}.',
  forgot_password: 'Forgot password?',
  old_password: 'Old Password',
  new_password: 'New Password',
  forgot_password_title: 'Reset Password',
  forgot_password_btn: 'Send Reset Instructions',
  change_password_title: 'Change Password',
  failed_password_change: 'Old password does not match',
  success_password_change: 'Password updated successfully',

  sign_in: 'Sign In',
  sign_up: 'Sign Up',
  start_your: 'Start your fundraiser!',
  dont_have: "Don't have an account?",
  already_have: 'Already have an account?',
  go_back: 'Go back',

  edit_campaign: 'Edit Campaign',
  log_out: 'Log out',
  activity: 'Activity',
  settings: 'Settings',

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: '{{count}} characters remaining',
  start_a_campaign_btn: 'Start a Campaign',
  campaign_photo: 'Fundraising page image',
  recommended_photo_size: 'Recommended photo size: 1240 x 600 pixels',
  edit_profile_title: 'Edit/Update Profile',
  edit_campaign_title_individual: 'Edit Individual Fundraising Page',
  edit_campaign_title_team: 'Edit Team Fundraising Page',
  edit_campaign_title_team_member: 'Edit Team member Fundraising Page',
  return_back_btn: 'Return Back to Profile',
  changes_saved_btn: 'Changes Saved!',
  no_changes_btn: 'No Changes to Save',
  browse: 'browse',
  drag_drop_pic: 'Drag and drop a picture to upload',
  cancel_image_upload: 'Cancel image upload',

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question:
    'Are you sure you want to delete this fundraising page?',

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: 'Join the Team',

  // CampaignForm
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: 'Join {{name}}',
  join_as_individual_btn: 'Join as Individual',
  create_a_team_btn: 'Create a Team',
  create_a_new_team_btn: 'Create a New Team',
  welcome: 'Welcome',
  individual_select_message:
    'Please select if you would like to fundraise a team, or review your profile.',
  member_select_message:
    'Please select if you would like to fundraise a new team, or review your profile.',
  team_select_message: 'Please review your profile.',
  ind_or_team_message:
    'Please select if you would like to fundraise individually, or create a fundraising team.',
  click_here: 'click here',
  prefer_individual: 'If you would prefer to start a team fundraiser,',
  prefer_team: 'If you would prefer to start an individual fundraiser,',

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  donated_by: 'Donated By',
  campaign_supporters: 'Campaign Supporters',
  campaign_sponsor: 'Our Generous Sponsors and Partners',

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name:
    'Enter a display name here - it will appear on the fundraising page',
  leave_message:
    "Share your message of support - this will appear on the organization or fundraisers' page so they know they received a gift from you (optional)",
  support_display_all: 'Please display my name, message and donation amount.',
  support_hide_amount:
    'Please display my name and message only (hides donation amount).',
  support_hide_name:
    'Please display my donation amount and message only (hides name).',
  support_hide_all: 'No, please hide my name, message and donation amount.',
  email_exists: 'This email address has already been registered',

  anonymous: 'Anonymous',
  fundraising_team_for: 'Fundraising team for:',
  fundraising_for: 'Fundraising for:',
  fundraising_team_member: 'Member of:',

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: 'Team Members',
  no_pages_to_display: 'No fundraising pages to display.',
  fundraising_teams: 'Fundraising Teams',
  individual_fundraisers: 'Individual Fundraisers',

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: 'Individual member of:',
  team_member_of: 'Team member of:',
  role_team_member: '(Role: Team Member)',
  role_team_captain: '(Role: Team Captain)',

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages: 'You do not have any individual fundraising pages.',
  no_team_pages: 'You do not have any team fundraising pages.',

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: 'Constituent Number',
  update_saved_message: 'Update saved successfully',

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: '404',
  subtitle_404: "Sorry, we couldn't find your page.",
  message_404: 'Take me back to -',
  home_page: 'Home page',

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: 'Creating a New Gift',

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: 'Cancel Donation',
  back: 'Back',
  notPaymentScheduleMessage: 'No payment schedule available',

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: 'Start by entering a',

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx
  note: 'Note:',
  FeltPressure: 'Donor felt pressure from fundraiser',
  NoLongerAfford: 'No longer can afford',
  UnderMinimumWage: 'Under minimum age',
  SpouseObjected: 'Spouse objected',
  ChangedMind: 'Changed mind',
  IntendedShortTerm: 'Donor intended for a short term donation',
  IntendedOneTime: 'Donor intended a one-time donation',
  Deceased: 'Deceased',
  Misunderstood: 'Misunderstood commitment',
  OtherCharities: 'Donating to other charities',
  PaymentDeclined: 'Payment declined',
  Complaint: 'Complaint',
  AccountClosed: 'Account closed',
  AlreadyDonating: 'Already donating',
  NoReason: 'No reason given',

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: 'Keep me signed in',
  session_timeout: 'Session Timeout',
  session_message:
    'The current session is about to expire in <1>{{countdown}}</1> seconds.',
  continue_confirmation: 'Would you like to continue?',

  // DonateBox & DonateButton CP
  master_donate_btn: 'Donate to this Campaign',
  team_donate_btn: 'Donate to the Team',
  team_member_donate_btn: 'Donate to {{name}} Fundraiser',

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: 'Donation was made through',
  donation: 'Donation',

  // OCP DonationGroup > Accordion
  why_donate_monthly: 'What impact does a monthly donation have?', // frequency

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  Completed: 'Completed',
  Terminated: 'Cancelled',
  Failed: 'Failed',
  TransactionRefund: 'Refund',
  InProgress: 'In Progress',

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: 'No payment method available',

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox:
    'Include my membership in my <1>{{donation}}</1> <2>{{donationFrequency}}</2> donation.',
  membership_order_label: 'Please select (optional)',

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: 'Get involved',
  set_up_fundraising_btn: 'Set up my fundraising campaign',
  start_fundraising_accordian_title: 'Support a campaign',

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description:
    "Click 'Your donation' to change the amount of your gift",
  summary_link_subscribe_description: `Click 'Your Subscription' to change the amount`,
  unexpected_error_message: `An error has occurred. But your transaction has been processed and will appear on your statement. <1>Do not try again.</1><br /><br /><2>Click here</2> or contact our donor services team immediately to advise you've received this message.`,
  applePay_error:
    'Apple Pay is only available when enabled with iOS compatible devices (iPhone/iPad/Mac).',
  googlePay_error: 'GooglePay is not enabled and/or available on this device.',

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg:
    'If you have any comments related to your donation, please include them here.',
  notes_or_direction:
    'Share any additional comments or direction related to your donation below',
  pion_additional_info_msg: 'Your comments here',
  pion_notes_or_direction: `Type in the missionary's name if you do not know the account number, or if you have other comments:`,
  halifaxyarmouth_additional_info_msg: 'Please add the name of your parish',
  halifaxyarmouth_notes_or_direction: `Please add the name of your parish.`,
  ucc_additional_info_msg:
    'If you would like to apply your gift to your Community of Faith Mission & Service total, please indicate the name of your town/city and faith community here.',
  ucc_notes_or_direction: `To credit a faith community`,
  nols_additional_info_msg:
    'If you have any comments related to your donation, please include them here.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: 'Add your profile picture:',
  upload_profile_picture_checkbox: "I'd like to upload a profile picture",

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: 'sort by',
  top_fundraisers: 'Top fundraisers',
  most_recent: 'Most recent',
  alphabetical: 'Alphabetical',

  // CAMPAIGN PAGES!!!!!!!!!!
  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: 'Change Profile Picture',
  start_p2p_btn: 'Start Fundraising',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: 'I want to Donate to this Campaign',
  team_title_donate_now: 'I want to Donate to this Team',
  title_start_fundraiser: 'I want to Start my own Fundraiser',
  title_join_team: 'I want to Fundraise as a Team',
  title_view_team: 'See your teams fundraising activity',
  title_join_individual: 'I want to Fundraise as an Individual',
  title_view_individual: 'See your fundraising activity',
  master_title_join_team: 'I want to Fundraise as a Team',
  team_title_join_team: 'I want to Join this Team',
  donate_this_campaign: 'Donate to this Campaign',
  donate_now: 'Donate\nNow',
  start_fundraiser: 'Start Fundraising Now',
  create_fundraiser: 'Create a\nFundraiser',
  master_join_team: 'Create a\nTeam',
  team_join_team: 'Join this\nTeam',
  view_team: 'View your\nteam page',
  join_individual: 'Join as an\nIndividual',
  view_individual: 'View your\npage',
  // END P2P actionButtonSet and P2P Action
  view_dashboard: 'View dashboard',
  donate_today: 'Donate\nToday',
  select_heading: 'Please select the gift you wish to give:',
  choose_details: `Choose the details you would like to display on the organization or fundraiser's page:`,
  required_pwd: 'Please Enter your password',
  pwd_match:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  // END CAMPAIGN PAGES!!!!!!!!!!
};

// Deprecated Component: QuickLinksBox - src\components\molecules\quickLinksBox\index.tsx
// Deprecated Component: DonateBox - src\components\molecules\donateBox\index.tsx
// Deprecated Component: ManagePrefsBox - ManagePrefsBox - src\components\molecules\managePrefsBox\index.tsx
// Deprecated Component: P2pTabs - src\components\molecules\p2pTabs\index.tsx
// share_btn: 'Share', // Deprecated Component: ShareMenu - molecules\shareMenu\index.tsx
// now_booking: 'Now Booking', // EP Deprecated Component: EventOverview - organism\eventOverview\index.tsx
// book_tickets: 'Book Tickets', // EP Deprecated Component: EventOverview - organism\eventOverview\index.tsx
