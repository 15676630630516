import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'qs';
import {
  getMembershipGroups,
  getCustomQuestions,
  getConsentQuestions,
  getAddressAutocompleteResults,
  getStripeIntentResult,
  postRecaptchaResponse,
  getAdyenIntentResult,
} from '@api/app';
import {
  DonationPageType,
  GivingFrequency,
  QuestionTypeCode,
} from '@root/enums';
import { search, updateContactDetails, getPaymentMethods } from '@api/contact';
import { fetchPaymentSchedule } from '@root/modules/donation/donation.slice';
import {
  AppState,
  ContactDetails,
  AmountAndFrequency,
  SelectedMembership,
  // CustomQuestionsCategories,
  CustomQuestions,
  PaymentMethod,
  Steps,
  AdditionalInformation,
  StripeIntent,
  ConfirmationScreenDetails,
  DoubleTheDonation,
  AdyenIntentRequest,
  SessionDetails,
  UTMParamsPost,
} from '@app/types';
import { AppThunk } from './AppThunk';

const queryString = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});

const appContainer = document.getElementById('root');
const { clientCode, clientHomepage, pageType, sentryKey, gtmId } =
  appContainer.dataset;
// const clientHomepage = appContainer.dataset.clienthomepage;
// const donationPageType = appContainer.dataset.donationpagetype;
// const sentryKey = appContainer.dataset.sentrykey;
// const gtmId = appContainer.dataset.gtmid;
const doubleTheDonationIdentifier = 'MISSI-qnzt26apPirLLaSN';

const initialState: AppState = {
  clientCode,
  clientHomepage,
  contactDetails:
    Object.keys(queryString).length >= 1 &&
    pageType &&
    pageType !== DonationPageType[DonationPageType.OCP]
      ? {
          familyId: queryString.b_fid ? queryString.b_fid : null,
          firstName: queryString.fn ? queryString.fn : null,
          lastName: queryString.ln ? queryString.ln : null,
          salutation: queryString.tl ? queryString.tl : null,
          address1Line1: queryString.al1 ? queryString.al1 : null,
          address1Line2: queryString.al2 ? queryString.al2 : null,
          address1City: queryString.ct ? queryString.ct : null,
          address1StateOrProvince: queryString.cty ? queryString.cty : null,
          address1Country: queryString.ctry ? queryString.ctry : null,
          address1PostalCode: queryString.ptl ? queryString.ptl : null,
          emailAddress1: queryString.ea ? queryString.ea : null,
          telephone1: queryString.ph ? queryString.ph : null,
        }
      : null,
  sessionDetails: null,
  stripeIntent: null,
  amountAndFrequency: null,
  coverCosts: null,
  acceptNotice: false,
  completeOrderTotal: null,
  isLoading: false,
  isLoadingContactDetails: false,
  isLoadingPaymentMethods: false,
  updateCompleted: false,
  error: null,
  contactSearchError: null,
  selectedMembership: null,
  additionalInformation: null,
  preferenceQuestions: null,
  queryQuestions: null,
  consentQuestions: null,
  selectedNewGift: false,
  paymentMethods: [],
  cancellationStatus: false,
  giftAidClaimAmount: 0,
  giftAidCheckbox: false,
  contactSignUp: null,
  membershipCheckbox: false,
  creditCardNo: null,
  expiryMM: null,
  expiryYY: null,
  cvcNo: null,
  membershipGroups: null,
  preferenceQuestionsArray: null,
  isEmptyCustomQuestions: false,
  consentQuestionsArray: null,
  consentComplete: false,
  addressSuggestions: [null],
  sentryKey,
  verificationAddressResult: null,
  progressIndicator: null,
  adyenIntentRequest: null,
  adyenIntentResult: null,
  adyenSessionId: null,
  donationFlowSteps: [],
  designationId: null,
  orderFullTotal: null,
  gtmId,
  ptxVerification: false,
  recurring: false,
  stripeIntentResult: null,
  recaptchaResponse: null,
  errorLogResult: null,
  errorMessageReset: false,
  confirmationDetails: null,
  doubleTheDonation: {
    active: false,
    identifier: doubleTheDonationIdentifier,
  },
  UTMParamsPost: null,
};

function startLoading(state: AppState) {
  state.isLoading = true;
}

function loadingFailed(state: AppState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getClientCodeStart: startLoading,
    getClientCodeSuccess(state, action: PayloadAction<string>) {
      state.clientCode = action.payload.toLowerCase();
      state.error = null;
      state.isLoading = false;
    },
    getClientCodeFailed: loadingFailed,
    getMembershipGroupsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getMembershipGroupsSuccess(state: AppState, action: PayloadAction<any>) {
      state.membershipGroups = action.payload;
      state.isLoadingContactDetails = false;
    },
    getMembershipGroupsFailure(state: AppState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoadingContactDetails = false;
    },
    getCustomQuestionsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getCustomQuestionsSuccess(state: AppState) {
      state.isLoadingContactDetails = false;
    },
    getCustomQuestionsFailure(state: AppState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoadingContactDetails = false;
    },
    getConsentQuestionsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getConsentQuestionsSuccess(state: AppState) {
      state.isLoadingContactDetails = false;
    },
    getConsentQuestionsFailure(state: AppState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoadingContactDetails = false;
    },
    getContactDetailsStart(state: AppState) {
      state.isLoadingContactDetails = true;
    },
    getContactDetailsSuccess(
      state: AppState,
      action: PayloadAction<ContactDetails>
    ) {
      state.contactDetails = action.payload;
      state.isLoadingContactDetails = false;
      state.contactSearchError = null;
    },
    getContactDetailsFailure(state: AppState, action: PayloadAction<string>) {
      state.contactSearchError = action.payload;
      state.isLoadingContactDetails = false;
    },
    // -Pages/Donate - greg
    setAmountAndFrequency(
      state: AppState,
      action: PayloadAction<AmountAndFrequency>
    ) {
      state.amountAndFrequency = action.payload;
      if (action.payload.givingFrequency !== GivingFrequency.Single) {
        state.recurring = true;
      } else {
        state.recurring = false;
      }
    },
    // Captures user values for each session
    setSessionDetails(state, action: PayloadAction<SessionDetails>) {
      state.sessionDetails = action.payload;
      if (action.payload.frequency !== GivingFrequency.Single) {
        state.recurring = true;
      } else {
        state.recurring = false;
      }
    },
    // Used to track the order total accross donationflow
    setCompleteOrderTotal(state, action: PayloadAction<string | number>) {
      state.completeOrderTotal = action.payload;
    },
    // Complete messaging
    setUpdateComplete(state, action: PayloadAction<boolean>) {
      state.updateCompleted = action.payload;
    },
    // -Pages/Payment
    setCoverCosts(state, action: PayloadAction<boolean>) {
      state.coverCosts = action.payload;
    },
    setPaymentNotice(state, action: PayloadAction<boolean>) {
      state.acceptNotice = action.payload;
    },
    setSelectedMembership(state, action: PayloadAction<SelectedMembership>) {
      state.selectedMembership = action.payload;
    },
    setAdditionalInformation(
      state,
      action: PayloadAction<AdditionalInformation>
    ) {
      state.additionalInformation = action.payload;
    },
    setEmptyCustomQuestions(state, action: PayloadAction<boolean>) {
      state.isEmptyCustomQuestions = action.payload;
    },
    setCustomQuestions(state, action: PayloadAction<CustomQuestions>) {
      state.preferenceQuestions = action.payload;
    },
    setQueryQuestions(state, action: PayloadAction<CustomQuestions>) {
      state.queryQuestions = action.payload;
    },
    setConsentQuestions(state, action: PayloadAction<CustomQuestions>) {
      state.consentQuestions = action.payload;
    },
    setConsentComplete(state, action: PayloadAction<boolean>) {
      state.consentComplete = action.payload;
    },
    setSelectedNewGift(state, action: PayloadAction<boolean>) {
      state.selectedNewGift = action.payload;
    },
    setAdyenIntentRequest(state, action: PayloadAction<AdyenIntentRequest>) {
      state.adyenIntentRequest = action.payload;
    },
    setAdyenIntentResult(state, action: PayloadAction<any>) {
      state.adyenIntentResult = action.payload;
    },
    setAdyenSessionId(state, action: PayloadAction<string>) {
      state.adyenSessionId = action.payload;
    },
    setProgressIndicator(state, action: PayloadAction<string>) {
      state.progressIndicator = action.payload;
    },
    setDonationFlowSteps(state, action: PayloadAction<Steps[]>) {
      state.donationFlowSteps = action.payload;
    },
    setOrderFullTotal(state, action: PayloadAction<string | number>) {
      state.orderFullTotal = action.payload;
    },
    setConfirmationDetails(
      state,
      action: PayloadAction<ConfirmationScreenDetails>
    ) {
      state.confirmationDetails = action.payload;
    },
    getPaymentMethodsStart(state: AppState) {
      state.isLoadingPaymentMethods = true;
    },
    getPaymentMethodsSuccess(state, action: PayloadAction<PaymentMethod[]>) {
      state.paymentMethods = action.payload;
      state.error = null;
      state.isLoadingPaymentMethods = false;
    },
    getPaymentMethodsFailed(state) {
      state.paymentMethods = null;
      state.error = null;
      state.isLoadingPaymentMethods = false;
    },
    setCreditCard(state, action: PayloadAction<string>) {
      state.creditCardNo = action.payload;
    },
    setExpiryMM(state, action: PayloadAction<string>) {
      state.expiryMM = action.payload;
    },
    setExpiryYY(state, action: PayloadAction<string>) {
      state.expiryYY = action.payload;
    },
    setCancellationStatus(state, action: PayloadAction<boolean>) {
      state.cancellationStatus = action.payload;
    },
    setGiftAidClaimAmount(state, action: PayloadAction<number>) {
      state.giftAidClaimAmount = action.payload;
    },
    setGiftAidCheckbox(state, action: PayloadAction<boolean>) {
      state.giftAidCheckbox = action.payload;
    },
    setContactSignUp(state, action: PayloadAction<boolean>) {
      state.contactSignUp = action.payload;
    },
    setMembershipCheckbox(state, action: PayloadAction<boolean>) {
      state.membershipCheckbox = action.payload;
    },
    setAddressSuggestions(state, action: PayloadAction<[Object]>) {
      state.addressSuggestions = action.payload;
    },
    setSentryKey(state, action: PayloadAction<string>) {
      state.sentryKey = action.payload;
    },
    getVerificationAddressResult(state, action: PayloadAction<any>) {
      state.verificationAddressResult = action.payload;
    },
    setDesignationId(state, action: PayloadAction<string>) {
      state.designationId = action.payload;
    },
    setGTMKey(state, action: PayloadAction<string>) {
      state.gtmId = action.payload;
    },
    setPTXVerification(state, action: PayloadAction<boolean>) {
      state.ptxVerification = action.payload;
    },
    setCustomerDetails(state, action: PayloadAction<StripeIntent>) {
      state.stripeIntent = action.payload;
    },
    setStripeIntentResult(state, action: PayloadAction<any>) {
      state.stripeIntentResult = action.payload;
    },
    setErrorLogResult(state, action: PayloadAction<any>) {
      state.errorLogResult = action.payload;
    },
    setRecaptchaResponse(state, action: PayloadAction<any>) {
      state.recaptchaResponse = action.payload;
    },
    setErrorMessageReset(state, action: PayloadAction<boolean>) {
      state.errorMessageReset = action.payload;
    },
    setDoubleTheDonation(state, action: PayloadAction<DoubleTheDonation>) {
      state.doubleTheDonation = action.payload;
    },
    setUTMParamsPost(state, action: PayloadAction<UTMParamsPost>) {
      state.UTMParamsPost = action.payload;
    },
  },
});

export const {
  setSentryKey,
  setGTMKey,
  getClientCodeStart,
  getClientCodeSuccess,
  getClientCodeFailed,
  getMembershipGroupsStart,
  getMembershipGroupsSuccess,
  getMembershipGroupsFailure,
  getCustomQuestionsStart,
  getCustomQuestionsSuccess,
  getCustomQuestionsFailure,
  getConsentQuestionsStart,
  getConsentQuestionsSuccess,
  getConsentQuestionsFailure,
  getContactDetailsStart,
  getContactDetailsSuccess,
  getContactDetailsFailure,
  setSessionDetails,
  setAmountAndFrequency,
  setCoverCosts,
  setPaymentNotice,
  setCompleteOrderTotal,
  setSelectedMembership,
  setAdditionalInformation,
  setEmptyCustomQuestions,
  setCustomQuestions,
  setQueryQuestions,
  setConsentQuestions,
  setConsentComplete,
  setSelectedNewGift,
  getPaymentMethodsStart,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,
  setUpdateComplete,
  setCancellationStatus,
  setGiftAidClaimAmount,
  setGiftAidCheckbox,
  setContactSignUp,
  setMembershipCheckbox,
  setCreditCard,
  setExpiryMM,
  setExpiryYY,
  setAddressSuggestions,
  getVerificationAddressResult,
  setProgressIndicator,
  setAdyenIntentResult,
  setAdyenIntentRequest,
  setAdyenSessionId,
  setOrderFullTotal,
  setDonationFlowSteps,
  setDesignationId,
  setPTXVerification,
  setCustomerDetails,
  setStripeIntentResult,
  setRecaptchaResponse,
  setErrorLogResult,
  setErrorMessageReset,
  setConfirmationDetails,
  setDoubleTheDonation,
  setUTMParamsPost,
} = appSlice.actions;

export default appSlice.reducer;

export const fetchMembershipGroups = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getMembershipGroupsStart());
    const membershipGroups = await getMembershipGroups();
    dispatch(getMembershipGroupsSuccess(membershipGroups));
  } catch (err) {
    dispatch(getMembershipGroupsFailure(err.toString()));
  }
};

export const fetchCustomQuestions =
  (pageId: string, pageKind: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCustomQuestionsStart());
      const preferenceQuestionsArray = await getCustomQuestions(
        pageId,
        pageKind
      );
      if (preferenceQuestionsArray && preferenceQuestionsArray.length === 0) {
        dispatch(setEmptyCustomQuestions(true));
        return;
      }

      dispatch(getCustomQuestionsSuccess());
      let query: CustomQuestions = {};
      let custom: CustomQuestions = {};
      const questions: CustomQuestions = preferenceQuestionsArray.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.preferenceQuestionId]: { ...item },
          }),
        {}
      );

      Object.keys(questions).forEach((id) => {
        const question = questions[id];
        const options = question.checkboxQuestionOptions;
        Object.assign(question, { checkboxQuestionOptions: { ...options } });

        Object.keys(queryString).forEach((qstr) => {
          if (
            qstr === question.questionName &&
            question.questionTypeCode === QuestionTypeCode.Internal
          ) {
            Object.assign(question, {
              response: queryString[qstr],
            });
          }
        });
        const { questionTypeCode } = question;
        if (questionTypeCode === QuestionTypeCode.Internal) {
          query = { ...query, [question.preferenceQuestionId]: question };
        } else if (questionTypeCode === QuestionTypeCode.Custom) {
          custom = { ...custom, [question.preferenceQuestionId]: question };
        }
      });

      if (Object.keys(query).length !== 0) {
        dispatch(setQueryQuestions(query));
      }

      if (Object.keys(custom).length !== 0) {
        dispatch(setCustomQuestions(custom));
      } else {
        dispatch(setEmptyCustomQuestions(true));
      }
    } catch (err) {
      dispatch(getCustomQuestionsFailure(err.toString()));
    }
  };

export const fetchConsentQuestions =
  (pageId: string, pageKind: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getConsentQuestionsStart());
      const consentQuestionsArray = await getConsentQuestions(pageId, pageKind);
      if (consentQuestionsArray && consentQuestionsArray.length === 0) {
        return;
      }
      dispatch(getConsentQuestionsSuccess());
      const consentQuestions: CustomQuestions = consentQuestionsArray.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.preferenceQuestionId]: {
              ...item,
              questionTypeCode: QuestionTypeCode.Consent,
            },
          }),
        {}
      );

      if (Object.keys(consentQuestions).length > 0) {
        Object.keys(consentQuestions).forEach((id) => {
          delete consentQuestions[id].preferenceQuestionId;
          const options = consentQuestions[id].checkboxQuestionOptions;
          Object.assign(consentQuestions[id], {
            checkboxQuestionOptions: { ...options },
          });
        });
        dispatch(setConsentQuestions(consentQuestions));
      }
    } catch (err) {
      dispatch(getConsentQuestionsFailure(err.toString()));
    }
  };

export const contactSearch =
  (constituentNumber: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactDetailsStart());
      const contactDetails = await search(constituentNumber);
      dispatch(getContactDetailsSuccess(contactDetails));
      dispatch(fetchPaymentSchedule(contactDetails.contactId));
      dispatch(fetchContactPaymentMethods(contactDetails.contactId));
    } catch (err) {
      dispatch(getContactDetailsFailure(err.message));
    }
  };

export const updateContact =
  (contactDetails: ContactDetails): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContactDetailsStart());
      const newContactDetails = await updateContactDetails(contactDetails);
      dispatch(getContactDetailsSuccess(newContactDetails));
      dispatch(setUpdateComplete(true));
      setTimeout(() => {
        dispatch(setUpdateComplete(false));
      }, 5000);
    } catch (err) {
      dispatch(getContactDetailsFailure(err.message));
    }
  };

export const fetchContactPaymentMethods =
  (constituentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentMethodsStart());
      const paymentMethods = await getPaymentMethods(constituentId);
      dispatch(getPaymentMethodsSuccess(paymentMethods));
    } catch (err) {
      dispatch(getPaymentMethodsFailed());
    }
  };

// Fetching AutoCompleted Address Results
export const fetchVerificationAddressResults =
  (formData: FormData, pageName: string): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await getAddressAutocompleteResults(formData, pageName);
      dispatch(getVerificationAddressResult(results));
    } catch (err) {
      console.error(err);
    }
  };

export const fetchStripeIntent =
  (formData: FormData, updateIntent?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await getStripeIntentResult(formData, updateIntent);
      if (results.success) {
        dispatch(setStripeIntentResult(results));
      } else {
        dispatch(
          setStripeIntentResult({
            status: 'failed',
            error: results.message,
          })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        setStripeIntentResult({
          status: 'failed',
          error: err,
        })
      );
    }
  };

export const fetchAdyenIntent =
  (intent: AdyenIntentRequest): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await getAdyenIntentResult(intent);
      dispatch(setAdyenIntentResult(results));
    } catch (err) {
      console.error(err);
      dispatch(
        setAdyenIntentResult({
          status: 'failed',
          error: err,
        })
      );
    }
  };

export const setRecaptchaRequest =
  (token: string, pageKind: string): AppThunk =>
  async (dispatch) => {
    try {
      // Fetch donation page details
      const results = await postRecaptchaResponse(token, pageKind);
      dispatch(setRecaptchaResponse(results));
    } catch (err) {
      console.error(err);
      dispatch(setErrorLogResult(err));
    }
  };
