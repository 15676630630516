// SPANISH (ES)

export default {
  // START Aria translation
  aria_enter: 'Sección de donación inicial',
  aria_flow:
    'Comenzando la sección {{routeName}}. Sección {{sectionIndex}} de {{totalSteps}}',
  // END Aria translation

  _verification: 'Verificación en curso...',
  _timeout:
    'Se ha producido un error de tiempo de espera. Por favor, inténtelo de nuevo más tarde.',
  _confirmation_boundary_error: `Se ha producido un error. Pero su transacción ha sido procesada y aparecerá en su estado de cuenta.`,
  _unable_loading_error: `No se puede cargar contenido en este momento.`,
  _configuration_error: 'Ocurrió un error de configuración',
  _loading: 'Cargando...',
  _loading_image: 'Cargando imagen...',
  _select: 'Seleccione',
  _month: 'Mes',
  _year: 'Año',
  _or: 'O',

  amount: '<1>{{currencySymbol}}{{amount}}</1>', // not in use

  processing: {
    donation_donate: 'Procesando su donación...',
    donation_subscribe: 'Procesando tus suscripcione...',
    contact: 'Actualización de su información de contacto...',
    paymentInfo: 'Actualización de su información de pago...',
    failure_message: 'No se pudo procesar el pago',
    intent_failure_message: 'No se pudo crear la intención de pago',
    fetching_pages: 'Obteniendo páginas de recaudación de fondos...',
    recaptcha_token_error:
      'No se pudo recuperar el token reCAPTCHA en este momento. Inténtelo de nuevo más tarde.',
    recaptcha_failure:
      'Solicitud incorrecta, token de recaptcha incorrecto. Por favor verifique su información e inténtelo nuevamente.',
  },

  image: {
    no_alt:
      'Se cargó una imagen dinámica pero no se proporcionó ningún texto alternativo.',
  },

  title: {
    how_often_donate: '¿Con qué frecuencia le gustaría donar?',
    select_amount_donate: 'Seleccione la cantidad que le gustaría dar:',
    select_amount_subscribe: 'Actualmente estás suscrito para donar:',
    direct_your_gift: '¿Cómo le gustaría dirigir su don?',
    bank: 'Banco',
    basc: 'BACS Direct Debit',
    select_payment_method: 'Selecciona tu forma de pago',
    wallets: 'Carteras',
    apple_pay: 'Pago de Apple',
    google_pay: 'Pago de Google',
    card: 'Tarjeta',
    lifetimeGiving: 'Toda la vida',
    givingHistory: 'Dando Historia',
    thisYearGiving: 'Este año',
    lastGiftAmount: 'Monto del último regalo',
    recurringGift: 'Regalo recurrente',
    creditCard: 'Tarjeta de crédito',
    childRecurringGift: 'Estado de regalo recurrente para niños',
    next: 'Próximo',
    last: 'Último',
    cardType: 'Tipo de tarjeta',
    expiry: 'Expiración',
    cancelled: 'Cancelada',
    gift_aid: 'Ayuda gratuita',
    donor_details: 'Detalles del donante',
    subscribe_details: 'Detalles del suscriptor',
    payment_details: 'Detalles del pago',
    additionalInfo: 'información adicional',
    membership: 'Afiliación',
    show_support: 'Comparte un mensaje de apoyo',
    share_on: 'Compartir en', // CP
    share_generosity: 'Comparta su generosidad e inspire a otros:',
    help_further: '¿Quieres ayudar más?',
  },

  button: {
    other_btn: 'Otro',
    cancel_btn: 'Cancelar',
    search_btn: 'Buscar',
    new_gift_btn: 'Nuevo regalo',
    create_new_contact_btn: 'Crear un regalo para un nuevo donante',
    update_contact_info: 'Actualizar la información de contacto',
    update_payment_info: 'Actualizar la información de pago',
    update_payment_schedule: 'Actualizar calendario de pagos',
    edit_btn: 'Editar',
    view_btn: 'Vista',
    share_btn: 'Cuota',
    save_changes: 'Guardar cambios',
  },

  navigation: {
    next_btn: 'próximo',
    prev_btn: 'Anterior',
    update_btn: 'Actualizar',
    finish_btn: 'Terminar',
    donate_btn: 'Donar',
    subscribe_btn: 'Suscripción',
    membership_btn: 'Afiliación',
    ptx_verification: 'Verificar',
    authorization:
      'Al completar este paso, usted autoriza el almacenamiento de esta cuenta de tarjeta por: <strong><1>{{amount}}</1> / por <2>{{recurring}}</2>, hasta su cancelación</strong>',
  },

  link: {
    read_more: 'Lee mas',
    show_less: 'Muestra menos',
    donate_monthly_link: 'Me gustaría donar mensualmente',
    donate_annually_link: 'Me gustaría donar anualmente',
    privacy_policy: 'Política de privacidad',
    terms_conditions: 'Términos y condiciones',
    your_donation: 'Tu donación <1>{{currency}}</1>',
    your_monthly_donation: 'Tu donación mensual <1>{{currency}}</1>',
    your_annually_donation: 'Tu donación anual <1>{{currency}}</1>',
    your_subscription: 'Tu suscripción <1>{{currency}}</1>',
    your_monthly_subscription: 'Tu suscripción mensual <1>{{currency}}</1>',
    your_annually_subscription: 'Tu suscripción anual <1>{{currency}}</1>',
    your_membership: 'Su membresía <1>{{currency}}</1>',
    giving: 'Dando <1>{{currency}}</1>',
  },

  forms: {
    no: 'No',
    yes: 'Sí',
    required: 'Requerida',
  },

  contactForm: {
    honour_dedication: 'En honor de...',
    memory_dedication: 'En memoria de...',
    no_send_tribute: 'No\ngracias',
    email_send_tribute: 'Sí, envía\nun correo\nelectrónico.',
    mail_send_tribute: 'Si, envía\nuna tarjeta',
    company_gift: '¿Esto es una donación corporativa',
    company: 'nombre de empresa',
    company_valication: 'Por favor, introduzca el nombre de la empresa',
    company_help_text:
      'El nombre de la empresa u organización que aparecerá en el acuse de recibo y / o recibo.',
    first_name: 'Primer nombre',
    first_name_validation: 'Por favor, introduzca su nombre de pila',
    last_name: 'Apellido',
    last_name_validation: 'Por favor ingrese su apellido',
    anonymous_help_text:
      'Esto ocultará su nombre y la información del donante de todas las comunicaciones públicas; sin embargo, la organización benéfica seguirá recibiendo su información.',
    email: 'Correo electrónico',
    email_validation:
      'Por favor, introduce una dirección de correo electrónico válida',
    email_help_text_donate:
      'Su acuse de recibo y / o recibo de impuestos se enviarán por correo electrónico aquí.',
    email_help_text_subscribe:
      'Cualquier reconocimiento o comunicación sobre esta suscripción se enviará aquí.',
    phone: 'Teléfono',
    phone_validation:
      'Ingrese un número de teléfono válido sin decimales ni guiones',
    mobile: 'Móvil',
    mobile_validation:
      'Por favor introduce un número de móvil válido sin decimales ni guiones',
    postal_code: 'Código postal',
    postal_code_validation: 'Ingrese un código postal válido',
    address: 'Dirección 1',
    address_validation: 'Por favor ingrese su direccion',
    address_auto_complete_help_text:
      'El campo de dirección 1 tiene activado el autocompletado. Se requieren 5 caracteres antes de proporcionar sugerencias.',
    address2: 'Dirección 2',
    city: 'Ciudad',
    city_validation: 'Por favor ingrese su ciudad',
    province: 'Provincia',
    province_validation: 'Por favor ingrese su provincia',
    country: 'País',
    country_validation: 'Ingrese un país válido',
    employer_matches_checkbox: 'Mi empleador igualará las donaciones',
    employer: 'Mi empleadora',
    honouree_label: 'Nombre del homenajeado',
    honouree_validation: 'Ingrese un nombre de homenajeado válido', // used for employer validation - missing?
    dedication_checkbox: 'Sí, mi donación es en honor o en memoria de alguien',
    tribute_type: '¿Quieres que te enviemos un email o una tarjeta?',
    recipient_email:
      'Por favor, introduzca la dirección de correo electrónico del destinatario',
    recipient_email_validation:
      'Ingrese el correo electrónico o la dirección física del destinatario',
    addressee_name: 'Enviar tarjeta a',
    addressee_name_validation: `Por favor ingrese el nombre del destinatario`,
    special_message: 'Comparta su mensaje especial aquí. (Opcional)',
    double_donation: '¡Vea si su empresa igualará su donación!',
  },

  questionsForm: {
    required_message: 'Se requieren respuestas a las siguientes preguntas.', // Google translation
    custom_questions_error:
      'Las respuestas a todas las preguntas son obligatorias', // Google translation
  },

  paymentForm: {
    // Bank
    transit_no: 'Número de Tránsito', // Google translation
    tran_no_validation: 'Ingrese un número de tránsito válido', // Google translation
    routing_no: 'Número de institución financiera y de tránsito (8 dígitos)',
    routing_no_validation:
      'Número de institución financiera y de tránsito se requiere',
    sort_code: 'Código de clasificacion', // Google translation
    sort_code_validation: 'Introduzca un código de clasificación válido', // Google translation
    bsb_no: 'Número BSB', // Google translation
    bsb_no_validation: 'Ingrese un número de BSB válido', // Google translation
    bank_check: 'Cheque bancario',
    bank_name: 'Nombre del banco',
    bank_name_validation: 'Se requiere el nombre del banco',
    bank_name_help_text: `El nombre del banco no puede exceder los 18 caracteres.`,
    acc_no: 'Número de cuenta',
    acc_no_validation: 'Número de cuenta se requiere',
    inst_no: 'Número de institución', // Google translation
    inst_no_validation: 'Ingrese un número de institución válido', // Google translation
    // Credit Card
    card_number: 'Número de tarjeta',
    card_validation:
      'Por favor, introduzca un número de tarjeta de crédito válida',
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      'Ingrese una fecha de vencimiento válida (MM). El primer dígito es 0 para meses de un solo dígito.',
    expiry_date_yy: 'YY',
    expiry_date_yy_validation: 'Ingrese una fecha de vencimiento válida (YY).',
    expiry_date_mmyy: 'MM/YY',
    card_security_code: 'CVV',
    card_security_code_validation: 'Por favor ingrese el CVV',
    use_existing_credit_card: 'Usar tarjeta de crédito existente',
    // PTX
    ptx_verified_message: 'Validación exitosa',
    adyen_error_message: `Se ha producido un tipo de error de pago: {{ resultCode }}, verifique su información o seleccione un método de pago diferente.`,
  },

  frequencySelector: {
    donate_single: 'Donar\nuna vez',
    donate_monthly: 'Donar\nmensualmente',
    donate_annually: 'Donar\nanualmente',
    subscribe_single: 'Suscripción\núnica',
    subscribe_monthly: 'Suscríbete\nmensualmente',
    subscribe_annually: 'Suscríbete\nanualmente',
  },

  amountSelector: {
    minimum_donation_of: 'Donación mínima de',
  },

  designationSelector: {
    greatest_need: 'Mayor necesidad',
    selection_label: 'Otro',
    order_label: 'Por favor seleccione',
  },

  giftAid: {
    giftAid_donate_checkbox:
      'Sí, me gustaría agregar una ayuda de regalo de <1>{{gift}}</1> (25% de la donación) además de mi donación de <2>{{donation}}</2>.',
    giftAid_subscribe_checkbox:
      'Sí, pago impuestos en el Reino Unido y quiero que tengan más significado con Gift Aid.',
  },

  confirmation: {
    thank_you: 'Gracias, {{name}}',
    donation_received_OCP: 'Se ha recibido tu donación.',
    donation_received:
      'Se ha recibido su donación de <1>{{donationAmount}}</1>.',
    subscription_received: `Deine suscripción in Höhe von <1>{{donationAmount}}</1> ist eingegangen.`,
    receipt: 'Se enviará un recibo de impuestos a <em>{{email}}</em>.',
    redirectMessage: 'Serás redirigido a:',
    seconds: 'segundos',
  },

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: 'Elevado', // CP
  goal: 'Objetivo', // CP
  of_goal: 'de objetivo', // CP
  supporters: 'Partidarios', // CP
  days_left: 'Días restantes', // CP

  Amount: 'Monto',
  Name: 'Nombre',
  Team: 'Equipo',
  Total: 'Total',

  individual_subtitle:
    'Comience su recaudación de fondos individual a continuación.',
  team_subtitle:
    'Comience la recaudación de fondos de su equipo a continuación.',
  campaign_title: '¡Vamos!',

  individual_page_name_label:
    'Nombre de la página de recaudación de fondos individual',
  team_page_name_label:
    'Nombre de la página de recaudación de fondos del equipo',
  team_member_page_name_label:
    'Nombre de la página de recaudación de fondos del miembro del equipo',
  individual_page_goal_label: 'Objetivo de recaudación de fondos individual',
  team_page_goal_label: 'Objetivo de recaudación de fondos del equipo',
  team_member_page_goal_label:
    'Objetivo de recaudación de fondos de los miembros del equipo',

  team_campaign_name: 'Nombre del equipo de recaudación de fondos',
  campaign_name: 'Nombre de la página de recaudación de fondos',
  campaign_name_validation:
    'Ingrese un nombre de campaña de recaudación de fondos válido',
  campaign_goal: 'Objetivo de recaudación de fondos',
  team_campaign_goal: 'Objetivo de recaudación de fondos del equipo',
  campaign_url: 'URL de campaña',

  create_campaign_btn: 'Crear página de recaudación de fondos',
  update_campaign_btn: 'Guardar cambios',
  delete_campaign_btn: 'Borra esta página',
  cancel_changes_btn: 'Cancelar cambios',

  password: 'Contraseña',
  password_validation: 'Por favor, introduzca su contraseña',
  password_reset_success:
    'Se han enviado instrucciones para restablecer la contraseña a {{email}}.',
  forgot_password: '¿Se te olvidó tu contraseña?',
  old_password: 'Contraseña anterior',
  new_password: 'Nueva contraseña',
  forgot_password_title: 'Restablecer la contraseña',
  forgot_password_btn: 'Enviar instrucciones de reinicio',
  change_password_title: 'Cambiar la contraseña',
  failed_password_change: 'La contraseña antigua no coincide',
  success_password_change: 'Contraseña actualizada exitosamente',

  sign_in: 'Iniciar sesión',
  sign_up: 'Inscribirse',
  start_your: '¡Comience su recaudación de fondos!',
  dont_have: '¿No tienes una cuenta?',
  already_have: '¿Ya tienes una cuenta?',
  go_back: 'Regresa',

  edit_campaign: 'Editar campaña',
  log_out: 'Cerrar sesión',
  activity: 'Actividad',
  settings: 'Ajustes',

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: 'Quedan {{count}} caracteres',
  start_a_campaign_btn: 'Iniciar una campaña',
  campaign_photo: 'Imagen de la página de recaudación de fondos',
  recommended_photo_size: 'Tamaño de foto recomendado: 1240 x 600 píxeles',
  edit_profile_title: 'Editar / actualizar perfil',
  edit_campaign_title_individual:
    'Editar página de recaudación de fondos individual',
  edit_campaign_title_team: 'Editar página de recaudación de fondos del equipo',
  edit_campaign_title_team_member:
    'Editar página de recaudación de fondos de miembros del equipo',
  return_back_btn: 'Regresar al perfil',
  changes_saved_btn: '¡Cambios guardados!',
  no_changes_btn: 'Sin cambios para guardar',
  browse: 'navegar',
  drag_drop_pic: 'Arrastra y suelta una imagen para cargar',
  cancel_image_upload: 'Cancelar carga de imagen',

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question:
    '¿Está seguro de que desea eliminar esta página de recaudación de fondos?',

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: 'Únete al equipo',

  // CampaignForm/
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: 'Únase a {{name}}',
  join_as_individual_btn: 'Únase como individuo',
  create_a_team_btn: 'Crear un equipo',
  create_a_new_team_btn: 'Crear un nuevo equipo',
  welcome: 'Bienvenidas',
  individual_select_message:
    'Seleccione si desea recaudar fondos para un equipo o revise su perfil.',
  member_select_message:
    'Seleccione si desea recaudar fondos para un nuevo equipo o revise su perfil.',
  team_select_message: 'Por favor revise su perfil.',
  ind_or_team_message:
    'Seleccione si desea recaudar fondos individualmente o crear un equipo de recaudación de fondos.',
  click_here: 'haga clic aquí',
  prefer_individual: 'Si prefiere iniciar una recaudación de fondos en equipo,',
  prefer_team: 'Si prefiere iniciar una recaudación de fondos individual,',

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  donated_by: 'Donado por',
  campaign_supporters: 'Partidarios de la campaña',
  campaign_sponsor: 'Nuestras generosas patrocinadores y socios',

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name:
    'Ingrese un nombre para mostrar aquí; aparecerá en la página de recaudación de fondos',
  leave_message:
    'Comparta su mensaje de apoyo: aparecerá en la página de la organización o de la recaudación de fondos para que sepan que recibieron un regalo de usted (opcional).',
  support_display_all:
    'Por favor muestre mi nombre, mensaje y monto de la donación.',
  support_hide_amount:
    'Por favor muestre solo mi nombre y mensaje (oculta el monto de la donación).',
  support_hide_name:
    'Por favor, muestre el monto de mi donación y el mensaje solamente (oculta el nombre).',
  support_hide_all:
    'No, por favor oculte mi nombre, mensaje y monto de la donación.',
  email_exists: 'Esta dirección de e-mail ya ha sido registrada',

  anonymous: 'Anónimo',
  fundraising_team_for: 'Equipo de recaudación de fondos para:',
  fundraising_for: 'Recaudación de fondos para:',
  fundraising_team_member: 'Miembro de:',

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: 'Miembros del equipo',
  no_pages_to_display: 'No hay páginas de recaudación de fondos para mostrar.',
  fundraising_teams: 'Equipos de recaudación de fondos',
  individual_fundraisers: 'Recaudadores de fondos individuales',

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: 'Miembro individual de:',
  team_member_of: 'Miembro del equipo de:',
  role_team_member: '(Rol: miembro del equipo)',
  role_team_captain: '(Rol: Capitán del equipo)',

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages:
    'No tiene páginas de recaudación de fondos individuales.',
  no_team_pages:
    'No tiene ninguna página de recaudación de fondos para el equipo.',

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: 'Número de constituyente',
  update_saved_message: 'Actualización guardada con éxito',

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: '404',
  subtitle_404: 'Lo sentimos, no pudimos encontrar tu página.',
  message_404: 'Llévame de vuelta a ...',
  home_page: 'Página de inicio',

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: 'Creando un nuevo regalo',

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: 'Cancelar donación',
  back: 'atrás',
  notPaymentScheduleMessage: 'No hay calendario de pagos disponible',

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: 'Comience ingresando un',

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx
  note: 'Nota:',
  FeltPressure: 'El donante sintió la presión de la recaudación de fondos',
  NoLongerAfford: 'Ya no puedo pagar',
  UnderMinimumWage: 'Por debajo de la edad mínima',
  SpouseObjected: 'Cónyuge objetado',
  ChangedMind: 'Mente cambiada',
  IntendedShortTerm: 'Donante destinado a una donación a corto plazo',
  IntendedOneTime: 'El donante pretendía hacer una donación única',
  Deceased: 'Fallecido',
  Misunderstood: 'Compromiso incomprendido',
  OtherCharities: 'Donar a otras organizaciones benéficas',
  PaymentDeclined: 'Pago rechazado',
  Complaint: 'Queja',
  AccountClosed: 'Cuenta cerrada',
  AlreadyDonating: 'Ya estoy donando',
  NoReason: 'Ninguna razón dada',

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: 'Mantenerme registrado',
  session_timeout: 'Hora de término de la sesión',
  session_message:
    'La sesión actual está a punto de caducar en <1>{{countdown}}</1> segundos.',
  continue_confirmation: '¿Te gustaria continuar?',

  // DonateBox & DonateButton CP
  master_donate_btn: 'Donar a esta campaña',
  team_donate_btn: 'Donar al equipo',
  team_member_donate_btn: 'Done a la recaudación de fondos de {{name}}',

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: 'La donación se hizo a través de',
  donation: 'Donación',

  // OCP DonationGroup > Accordion
  why_donate_monthly: '¿Qué impacto tiene una donación mensual?',

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  Completed: 'Terminado',
  Terminated: 'Cancelado',
  Failed: 'Ha fallado',
  TransactionRefund: 'Reembolso',
  InProgress: 'En curso',

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: 'No hay forma de pago disponible',

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox:
    'Incluir mi membresía en mi donación <1>{{donation}}</1> <2>{{donationFrequency}}</2>.',
  membership_order_label: 'Por favor seleccione (opcional)',

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: 'Involucrarse',
  set_up_fundraising_btn: 'Configurar mi campaña de recaudación de fondos',
  start_fundraising_accordian_title: 'Apoya una campaña',

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description: `Haga clic en 'Su donación' para cambiar el monto de su donación`,
  summary_link_subscribe_description: `Haga clic en 'Su suscripción' para cambiar el monto`,
  unexpected_error_message:
    'Se ha producido un error. Pero su transacción ha sido procesada y aparecerá en su estado de cuenta. <1>No lo vuelvas a intentar.</1><br /><br /><2>Haga clic aquí</2> o comuníquese con nuestro equipo de servicios para donantes de inmediato para informarle que recibió este mensaje.',
  applePay_error: `Apple Pay solo está disponible cuando está habilitado con dispositivos compatibles con iOS (iPhone / iPad / Mac).`,
  googlePay_error:
    'GooglePay no está habilitado ni disponible en este dispositivo.',

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg:
    'Si tiene algún comentario relacionado con su donación, inclúyalo aquí.',
  notes_or_direction:
    'Comparta cualquier comentario o dirección adicional relacionada con su donación a continuación',
  nols_additional_info_msg:
    'Si tiene algún comentario relacionado con su donación, inclúyalo aquí.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: 'Añade tu foto de perfil:',
  upload_profile_picture_checkbox: 'me gustaria subir una foto de perfil',

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: 'Ordenar por',
  top_fundraisers: 'Principales recaudadores de fondos',
  most_recent: 'Más reciente',
  alphabetical: 'Alfabética',

  // CAMPAIGN PAGES!!!!!!!!!!
  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: 'Cambiar foto de perfil',
  start_p2p_btn: 'Iniciar la recaudación de fondos',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: 'Quiero Donar a esta Campaña',
  team_title_donate_now: 'Quiero Donar a este Equipo',
  title_start_fundraiser: 'Quiero comenzar mi propia recaudación de fondos',
  title_join_team: 'Quiero recaudar fondos en equipo',
  title_view_team: 'Vea la actividad de recaudación de fondos de su equipo',
  title_join_individual: 'Quiero recaudar fondos como individuo',
  title_view_individual: 'Ver su actividad de recaudación de fondos',
  master_title_join_team: 'Quiero recaudar fondos en equipo',
  team_title_join_team: 'quiero unirme a este equipo',
  donate_this_campaign: 'Donar a esta Campaña',
  donate_now: 'Done\nahora',
  start_fundraiser: 'Comience a recaudar fondos ahora',
  create_fundraiser: 'Crear una recaudación\nde fondos',
  master_join_team: 'crear un\nequipo',
  team_join_team: 'Únete a este\nequipo',
  view_team: 'Ver la página\nde tu equipo',
  join_individual: 'Únete como\nindividuo',
  view_individual: 'ver tu\npágina',
  // END P2P actionButtonSet and P2P Action
  view_dashboard: 'Ver tablero',
  donate_today: 'Donar\nHoy',
  select_heading: 'Por favor seleccione el regalo que desea dar:',
  choose_details: `Elige los detalles que te gustaría mostrar en la página de la organización o de la recaudación de fondos:`,
  required_pwd: 'Por favor, introduzca su contraseña',
  pwd_match:
    'Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un carácter de caso especial',
  // END CAMPAIGN PAGES!!!!!!!!!!
};
